<template>
  <div class="branch-form__card">
    <h2 class="branch-form__card-header">
      {{ $t("common.contacts") }}
    </h2>

    <div class="branch-form__card-body">
      <div class="application-input-wrap">
        <div class="application-input-title">{{ $t('common.phoneNumberWithValue', {value: 1}) }}</div>
        <AppInputA
            v-model="form.phones[0].phone"
            class="application-input"
            type="number"
            :prepend="true"
            size="extra-large"
            placeholder="XX XXX XX XX"
        >
          <template v-slot:prepend>
            <div class="prepend-phone-code">
              <p>{{ $t("common.phonePrefixes.uzb") }}</p>
            </div>
          </template>
        </AppInputA>
        <div v-show="$v.form.phones.$each[0].$anyError" class="application-input-error">{{ $t("validations.requiredField") }} {{ $t("common.and") }} {{ $t("validations.mustBeNumber").toLowerCase() }}</div>
      </div>
      <div class="application-input-wrap">
        <div class="application-input-title">{{ $t('common.phoneNumberWithValue', {value: 2}) }}</div>
        <AppInputA
            v-model="form.phones[1].phone"
            class="application-input"
            type="number"
            :prepend="true"
            size="extra-large"
            placeholder="XX XXX XX XX"
        >
          <template v-slot:prepend>
            <div class="prepend-phone-code">
              <p>{{ $t("common.phonePrefixes.uzb") }}</p>
            </div>
          </template>
        </AppInputA>
        <div v-show="$v.form.phones.$each[1].$anyError" class="application-input-error">{{ $t("validations.requiredField") }} {{ $t("common.and") }} {{ $t("validations.mustBeNumber").toLowerCase() }}</div>
      </div>
      <div class="application-input-wrap">
        <div class="application-input-title">{{ $t('common.fax') }}</div>
        <AppInputA
            v-model="form.phones[2].phone"
            class="application-input"
            type="number"
            :prepend="true"
            size="extra-large"
            placeholder="XX XXX XX XX"
        >
          <template v-slot:prepend>
            <div class="prepend-phone-code">
              <p>{{ $t("common.phonePrefixes.uzb") }}</p>
            </div>
          </template>
        </AppInputA>
        <div v-show="$v.form.phones.$each[2].$anyError" class="application-input-error">{{ $t("validations.requiredField") }} {{ $t("common.and") }} {{ $t("validations.mustBeNumber").toLowerCase() }}</div>
      </div>

      <div class="application-input-wrap">
        <div class="application-input-title">{{ $t('common.email') }}</div>
        <AppInput
            v-model="form.email"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.email')"
        />
        <div v-show="$v.form.email.$anyError" class="application-input-error">{{ $t("validations.requiredField") }}</div>
      </div>

    </div>

  </div>
</template>

<script>
import AppInput from "@/components/partials/AppInput.vue";
import AppInputA from "@/components/partials/AppInputA.vue";

export default {
  name: "ContactsInfoCard",
  components: {
    AppInputA,
    AppInput
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    isTypeAtm: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set($value) {
        this.$emit("input", $value);
      }
    },
  },
  validations: {
    form: {
      phones: {
        $each: {
          phone: {
            required($val) {
              return this.isTypeAtm || !!$val;
            },
            isNumber($val) {
              return this.isTypeAtm || /^\d+$/.test($val);
            }
          }
        },
      },
      email: {
        required($val) {
          return this.isTypeAtm || !!$val;
        }
      }
    }
  },
}
</script>
