<template>
    <div class="col-9 cards">

        <create-card ref="create-card" v-model="form" :types="types" :regions="regions" :is-type-atm="isTypeAtm" />

        <contacts-info-card ref="contacts-info-card" v-show="!isTypeAtm" v-model="form" :is-type-atm="isTypeAtm" />

        <accounts-card ref="accounts-card" v-show="!isTypeAtm" v-model="form" :is-type-atm="isTypeAtm" />

        <coordinates-card ref="coordinates-card" v-model="form"/>

    </div>
</template>

<script>
import CreateCard from "@/components/admin/Branch/CreateOrEditForm/LeftSideCards/CreateCard.vue";
import ContactsInfoCard from "@/components/admin/Branch/CreateOrEditForm/LeftSideCards/ContactsInfoCard.vue";
import AccountsCard from "@/components/admin/Branch/CreateOrEditForm/LeftSideCards/AccountsCard.vue";
import CoordinatesCard from "@/components/admin/Branch/CreateOrEditForm/LeftSideCards/CoordinatesCard.vue";

export default {
    name: "LeftSide",
    emits: ["input"],
    components: {
        CoordinatesCard,
        AccountsCard,
        ContactsInfoCard,
        CreateCard
    },
    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        },
        types: {
            type: Array,
            required: true,
            default: () => []
        },
        regions: {
            type: Array,
            required: true,
            default: () => []
        },
        isTypeAtm: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    model: {
        prop: "value",
        event: "input"
    },
    computed: {
        form: {
            get() {
                return this.value;
            },
            set($value) {
                this.$emit("input", $value);
            }
        },
    },
}
</script>
