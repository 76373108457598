<template>
  <div class="col-3">

    <div class="branch-form__actions col-3">

      <button class="branch-form__actions-save" v-text="saveButtonText" @click.prevent="emit('click:submit')"/>

      <button class="branch-form__actions-back" v-text="$t('common.back')" @click="emit('click:back')"/>

    </div>

  </div>
</template>

<script>
export default {
  name: "RightSide",
  emits: ['click:submit', 'click:back'],
  props: {
    is_loading: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    saveButtonText() {
      return this.is_loading ? this.$t('common.pleaseWaitWithDots') : this.$t('common.save');
    },
  },
  methods: {
    emit(name) {
      this.$emit(name)
    }
  },
}
</script>
