<template>
  <div class="branch-form__card">

    <h2 class="branch-form__card-header">
      {{ $t("common.coordinates") }}
    </h2>

    <div class="branch-form__card-body">

      <div class="application-input-wrap">
        <div class="application-input-title">{{ $t('common.longitude') }}</div>
        <AppInput
            v-model="form.coordinate_longitude"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.longitude')"
        />
        <div v-show="$v.form.coordinate_longitude.$anyError" class="application-input-error">{{ $t("validations.requiredField") }}</div>
      </div>

      <div class="application-input-wrap">
        <div class="application-input-title">{{ $t('common.latitude') }}</div>

        <AppInput
            v-model="form.coordinate_latitude"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.latitude')"
        />
        <div v-if="$v.form.coordinate_latitude.$anyError" class="application-input-error">{{ $t("validations.requiredField") }}</div>
      </div>

    </div>

  </div>
</template>

<script>
import AppInput from "@/components/partials/AppInput.vue";
import {required} from "vuelidate/lib/validators";

export default {
  name: "CoordinatesCard",
  components: {AppInput},
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },
  model: {
    prop: "value",
    event: "input"
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set($value) {
        this.$emit("input", $value);
      },
    },
  },
  validations: {
    form: {
      coordinate_longitude: {
        required
      },
      coordinate_latitude: {
        required
      }
    }
  },
}
</script>
