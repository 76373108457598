<template>
  <div class="branch-form__card">
    <h2 class="branch-form__card-header">
      {{ $t("common.accounts.index") }}
    </h2>

    <div class="branch-form__card-body">

      <div class="application-input-wrap">
        <div class="application-input-title">{{ $t('common.accounts.humo') }}</div>
        <AppInput
            v-model="form.humo_account"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.accounts.humo')"
        />
        <div v-show="$v.form.humo_account.$anyError" class="application-input-error">{{ $t("validations.requiredField") }}</div>
      </div>

      <div class="application-input-wrap">
        <div class="application-input-title">{{ $t('common.accounts.uzcard') }}</div>
        <AppInput
              v-model="form.uzcard_account"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.accounts.uzcard')"
        />
        <div v-show="$v.form.uzcard_account.$anyError" class="application-input-error">{{ $t("validations.requiredField") }}</div>
      </div>

    </div>

  </div>
</template>

<script>
import AppInput from "@/components/partials/AppInput.vue";

export default {
  name: "AccountsCard",
  components: {AppInput},
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    isTypeAtm: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  computed: {
    form: {
      get() {
        return this.value;
      },
      set($value) {
        this.$emit("input", $value);
      }
    },
  },
  validations: {
    form: {
      humo_account: {
        required($val) {
          return this.isTypeAtm || !!$val;
        }
      },
      uzcard_account: {
        required($val) {
          return this.isTypeAtm || !!$val;
        }
      }
    }
  },
}
</script>
