export const BRANCH_FORM = () => ({
    id: null,
    name: "",
    mfo: "",
    tin: "",
    region_id: null,
    address: "",
    email: "",
    postal_code: "",
    approximately: "",
    phones: [
        {
            "phone": "",
            "type": "s",
            "main": true
        },
        {
            "phone": "",
            "type": "s",
            "main": false
        },
        {
            "phone": "",
            "type": "f",
            "main": false
        }
    ],
    humo_account: "",
    uzcard_account: "",
    coordinate_longitude: "",
    coordinate_latitude: "",
    atm_type: null,
    atm_no: "",
    branch_id: null,
    branch_type_id: null
});
