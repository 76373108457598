<template>
  <div class="branch-form">

    <left-side ref="left-side-form" v-model="form" :types="types" :regions="regions" :is-type-atm="isTypeAtm"/>

    <right-side :is_loading="is_loading" @click:submit="submit" @click:back="backToBranch"/>

  </div>
</template>
<script>
import {get, post, put} from "@/helpers/api";
import {translationExists} from "@/mixins/local/translationExists.mixin";
import LeftSide from "@/components/admin/Branch/CreateOrEditForm/LeftSide.vue";
import RightSide from "@/components/admin/Branch/CreateOrEditForm/RightSide.vue";
import {BRANCH_FORM} from "@/data/pages/Branch/BranchForm";

export default {
  name: "BranchForm",
  components: {
    RightSide,
    LeftSide
  },
  mixins: [translationExists],
  data() {
    return {
      is_loading: false,
      branch: '',
      types: [],
      regions: [],
      form: new BRANCH_FORM(),
    }
  },
  computed: {
    isTypeAtm() {
      const atmType = this.types.find(type => type.id === this.form.branch_type_id);

      return Boolean(atmType?.is_atm);
    },
  },
  methods: {
    resetForm() {
      this.form = new BRANCH_FORM();
    },
    backToBranch() {
      this.$router.push({path: '/branches', query: { type: this.$route.query.type }});
    },
    setFormValuesFromRoute() {
      const {branch_id} = this.$route.query;
      const {id} = this.$route.params;

      if (branch_id !== undefined && !id) {
        this.form.branch_id = branch_id;
      }

      if (id) {
        if (!branch_id) {
          this.loadBranch(parseInt(id));
        } else {
          this.form.branch_id = id;
          this.loadBranch(parseInt(branch_id));
        }
      }
    },
    async loadBranch(id) {
      try {
        this.is_loading = true;

        const response = await get(`/branches/${id}`)
        if (response.data.success) {
          this.form = {
            ...response.data.data,
            longitude: response.data.data.coordinate_longitude,
            latitude: response.data.data.coordinate_latitude
          }
        }
      } catch (e) {
        this.$router.push('/pages/404')
      } finally {
        this.is_loading = false
      }
    },
    async loadTypes() {
      try {
        this.is_loading = true;
        const response = await get('/branch-types');

        if (response.status === 200) {
          this.types = response.data.data.result
        }
      } catch (e) {
        this.$store.commit('notifications/error', 'errors.somethingWentWrong');
      } finally {
        this.is_loading = false;
      }

    },
    async loadRegions() {
      try {
        this.is_loading = true;
        const response = await get('/regions');

        if (response.status === 200) {
          this.regions = response.data.data
        }

      } catch (e) {
        this.$store.commit('notifications/error', 'errors.somethingWentWrong');
      } finally {
        this.is_loading = false;
      }
    },
    checkFormInvalid() {
      const cardRefs = ["coordinates-card", "accounts-card", "contacts-info-card", "create-card"];
      let isInvalid = false;

      cardRefs.forEach(ref => {
        const validationObject = this.$refs["left-side-form"].$refs[ref].$v;

        validationObject.$touch();

        if (validationObject.$invalid) {
          isInvalid = true;
        }
      });

      return isInvalid;
    },
    async submit() {

      if (this.checkFormInvalid()) {
        return;
      }

      this.is_loading = true;

      const url = this.form.id ? `/branches/${this.form.id}` : '/branches';
      const method = this.form.id ? put : post;
      const atmTypeName = this.types.find(type => type.id === this.form.branch_type_id).name ?? this.$t("branch.index");
      const notificationText = this.form.id ? "branch.edit.success" : "branch.create.success";

      try {
        const response = await method(url, this.form);

        if (response.data.success) {
          this.$store.commit('notifications/error', {title: "requestsStatuses.success.index", text: this.$t(notificationText, { branch: atmTypeName }), type: "success"})
          this.resetForm();
          this.$router.push({path: '/branches', query: { type: this.$route.query.type }});
        }

      } catch (e) {
        this.$store.commit('notifications/error', 'errors.somethingWentWrong');
      } finally {
        this.is_loading = false;
      }
    }

  },
  created() {
    this.loadTypes()
    this.loadRegions()
    this.resetForm()
    this.setFormValuesFromRoute()
  },
}
</script>

<style src="@/assets/scss/modules/pages/Branch/BranchForm.scss" lang="scss"></style>
