<template>
  <div class="branch-form__card">
    <h2 class="branch-form__card-header">
      {{ $t("branch.show.editOrCreate") }}
    </h2>

    <div class="branch-form__card-body">
      <div>
        <div class="application-input-wrap application-input-theme-login">
          <div class="application-input-title">
            {{ $t('common.type') }}
          </div>
          <AppSelect
            v-model="form.branch_type_id"
            :items="types"
            item-name="name"
            item-value="id"
            class="application-input"
            size="extra-large"
          />
          <div
            v-show="$v.form.branch_type_id.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }}
          </div>
        </div>
      </div>

      <div v-show="regions && !isTypeAtm">
        <div class="application-input-wrap application-input-theme-login">
          <div class="application-input-title">
            {{ $t('common.table.region') }}
          </div>
          <AppSelect
            v-model="form.region_id"
            :items="regions"
            item-name="name_uz"
            item-value="id"
            default-value=""
            class="application-input"
            size="extra-large"
          />
          <div
            v-show="$v.form.region_id.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }}
          </div>
        </div>
      </div>

      <div>
        <div class="application-input-wrap">
          <div class="application-input-title">
            {{ $t('common.form.name') }}
          </div>
          <AppInput
            v-model="form.name"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.form.name')"
          />
          <div
            v-show="$v.form.name.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }}
          </div>
        </div>
      </div>

      <div v-show="isTypeAtm">
        <div class="application-input-wrap application-input-theme-login">
          <div class="application-input-title">
            {{ $t('branch.show.atmType') }}
          </div>
          <AppSelect
            v-model="form.atm_type"
            :items="atmTypes"
            item-name="name"
            item-value="id"
            default-value=""
            class="application-input"
            size="extra-large"
          />
          <div
            v-show="$v.form.atm_type.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }}
          </div>
        </div>
      </div>

      <div v-show="isTypeAtm">
        <div class="application-input-wrap">
          <div class="application-input-title">
            {{ $t('branch.show.atmNumber') }}
          </div>
          <AppInput
            v-model="form.atm_no"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('branch.show.atmNumber')"
          />
          <div
            v-show="$v.form.atm_no.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }}
          </div>
        </div>
      </div>

      <div v-show="!isTypeAtm">
        <div class="application-input-wrap">
          <div class="application-input-title">
            {{ $t('common.form.mfo-or-code') }}
          </div>
          <AppInput
            v-model="form.mfo"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.form.mfo-or-code')"
          />
          <div
            v-show="$v.form.mfo.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }}
          </div>
        </div>
      </div>

      <div v-show="!isTypeAtm">
        <div class="application-input-wrap">
          <div class="application-input-title">
            {{ $t('common.tin') }}
          </div>
          <AppInput
            v-model="form.tin"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.tin')"
          />
          <div
            v-show="$v.form.tin.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }}
          </div>
        </div>
      </div>

      <div>
        <div class="application-input-wrap">
          <div class="application-input-title">
            {{ $t('common.address') }}
          </div>
          <AppInput
            v-model="form.address"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.address')"
          />
          <div
            v-show="$v.form.address.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }}
          </div>
        </div>
      </div>

      <div>
        <div class="application-input-wrap">
          <div class="application-input-title">
            {{ $t('common.referencePoint') }}
          </div>
          <AppInput
            v-model="form.approximately"
            class="application-input"
            type="text"
            size="extra-large"
            :placeholder="$t('common.referencePoint')"
          />
          <div
            v-show="$v.form.approximately.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }}
          </div>
        </div>
      </div>

      <div v-show="!isTypeAtm">
        <div class="application-input-wrap">
          <div class="application-input-title">
            {{ $t('common.postcode') }}
          </div>
          <AppInput
            v-model="form.postal_code"
            class="application-input"
            type="number"
            size="extra-large"
            :placeholder="$t('common.postcode')"
          />
          <div
            v-show="$v.form.postal_code.$anyError"
            class="application-input-error"
          >
            {{ $t("validations.requiredField") }} {{ $t("common.and") }} {{ $t("validations.mustBeNumber").toLowerCase() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppSelect from "@/components/partials/AppSelect.vue";
import AppInput from "@/components/partials/AppInput.vue";
import {translationExists} from "@/mixins/local/translationExists.mixin";
import {required} from "vuelidate/lib/validators";

export default {
  name: "CreateCard",
  mixins: [translationExists],
  components: {AppInput, AppSelect},
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    types: {
      type: Array,
      required: true,
      default: () => []
    },
    regions: {
      type: Array,
      required: true,
      default: () => []
    },
    isTypeAtm: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  computed: {
    atmTypes() {
      return [{ id: 1, name: "UZCARD" }, { id: 2, name: "HUMO" }];
    },
    form: {
      get() {
        return this.value;
      },
      set($value) {
        this.$emit("input", $value);
      }
    }
  },
  validations: {
    form: {
      branch_type_id: {
        required
      },
      region_id: {
        required($val) {
          return this.isTypeAtm || !!$val;
        }
      },
      name: {
        required
      },
      atm_type: {
        required($val) {
          return !this.isTypeAtm || !!$val;
        }
      },
      atm_no: {
        required($val) {
          return !this.isTypeAtm || !!$val;
        }
      },
      mfo: {
        required($val) {
          return this.isTypeAtm || !!$val;
        }
      },
      tin: {
        required($val) {
            return this.isTypeAtm || !!$val;
        }
      },
      address: {
        required
      },
      approximately: {
        required
      },
      postal_code: {
        required($val) {
          return this.isTypeAtm || !!$val;
        },
        isNumber($val) {
          return this.isTypeAtm || /^\d+$/.test($val);
        }
      }
    }
  },
};
</script>
